<template>
  <div class="editor-wrap">
    <div class="editor">
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
        <div class="menubar">
          <SvgIcon
            name="bold"
            :onClick="commands.bold"
            :isActive="isActive.bold()"
          ></SvgIcon>
          <SvgIcon
            name="italic"
            :onClick="commands.italic"
            :isActive="isActive.italic()"
          ></SvgIcon>
          <SvgIcon
            name="strikethrough"
            :onClick="commands.strike"
            :isActive="isActive.strike()"
          ></SvgIcon>
          <SvgIcon
            name="underline"
            :onClick="commands.underline"
            :isActive="isActive.underline()"
          ></SvgIcon>
          <SvgIcon
            name="link"
            :onClick="commands.link"
            :isActive="isActive.link()"
          ></SvgIcon>
          <SvgIcon
            name="image"
            :onClick="() => showImagePrompt(commands.image)"
          ></SvgIcon>

          <button
            class="menubar__button"
            @click="() => showCodeView(commands.code_view)"
            type="button"
          >
            Code
          </button>

          <SvgIcon
            name="paragraph"
            :onClick="commands.paragraph"
            :isActive="isActive.paragraph()"
          ></SvgIcon>
          <SvgIcon
            name="h1"
            level="1"
            :onClick="commands.heading"
            :isActive="isActive.heading({ level: 1 })"
          ></SvgIcon>
          <SvgIcon
            name="h2"
            level="2"
            :onClick="commands.heading"
            :isActive="isActive.heading({ level: 2 })"
          ></SvgIcon>
          <SvgIcon
            name="h3"
            level="3"
            :onClick="commands.heading"
            :isActive="isActive.heading({ level: 3 })"
          ></SvgIcon>
          <SvgIcon
            name="list_ui"
            :onClick="commands.bullet_list"
            :isActive="isActive.bullet_list()"
          ></SvgIcon>
          <SvgIcon
            name="blockquote-left"
            :onClick="commands.blockquote"
            :isActive="isActive.blockquote()"
          ></SvgIcon>
          <SvgIcon
            name="code-square"
            :onClick="commands.code_block"
            :isActive="isActive.code_block()"
          ></SvgIcon>
          <SvgIcon name="hr" :onClick="commands.horizontal_rule"></SvgIcon>
          <SvgIcon name="arrow-90deg-left" :onClick="commands.undo"></SvgIcon>
          <SvgIcon name="arrow-90deg-right" :onClick="commands.redo"></SvgIcon>
        </div>
      </editor-menu-bar>
      <div
        id="codemirror"
        v-show="isCodeViewMode"
        style="border: 1px solid #eee; padding: 8px"
      >
        <textarea ref="code_view"></textarea>
      </div>
      <editor-content
        v-show="!isCodeViewMode"
        style="border: 1px solid #eee; padding: 8px"
        :editor="editor"
      />
    </div>
  </div>
</template>

<script>
import SvgIcon from "./EditorSvgIcon";
import Icon from "./EditorIcon";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Image,
  Link,
  Strike,
  Underline,
  History,
  TrailingNode,
} from "tiptap-extensions";
import { CodeView } from "../plugins/tiptap/extensions.js";

import codemirror from "codemirror";
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/addon/selection/active-line.js"; // require active-line.js
import "codemirror/addon/edit/closetag.js"; // autoCloseTags

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    Icon,
    SvgIcon,
  },
  props: ['html'],
  data() {
    return {
      isCodeViewMode: false,
      valueChangedFromEditor: false,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Image(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new TrailingNode({
            node: "paragraph",
            notAfter: ["paragraph"],
          }),
          new CodeView({
            codemirror,
            codemirrorOptions: {
              styleActiveLine: true,
              autoCloseTags: true,
            },
          }),
          //new Fullscreen(),
        ],
        content: this.html,
        onUpdate: ({ getHTML }) => {
          // get new content on update
          const newContent = getHTML();
          this.$emit("onUpdate", newContent);
        },
      }),
    };
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  mounted() {
    const newContent = this.editor.getHTML();
    this.$emit("onUpdate", newContent);
  },
  watch: {
    html: {
      handler(newVal, oldVal) {
        // Only update if the value changed from parent component.
        if (!this.valueChangedFromEditor) {
          console.log(this.editor)
          // this.editor.html = newVal;
          console.log(`value changed: ${newVal}`);
        }
        this.valueChangedFromEditor = false;
      },
      immediate: true
    }
  },
  methods: {
    showImagePrompt(command) {
      const src = prompt("Enter the url of your image here");
      if (src !== null) {
        command({ src });
      }
    },
    showCodeView(command) {
      console.log(command);
      this.isCodeViewMode = !this.isCodeViewMode;
      command({
        ref: this.$refs.code_view,
        open: this.isCodeViewMode,
        editor: this.editor,
        component: this,
      });
    },
  },
};
</script>

<style scoped>
/* #codemirror {
    display: flex;
    flex-grow: 1;
    font-size: 16px;
    line-height: 24px;
    overflow: scroll;
  }
  #codemirror .CodeMirror {
    flex-grow: 1;
    height: auto;
  } */
</style>
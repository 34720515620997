<template>
  <section class="page-section p-0 mt-3 pb-5">
    <div class="row">
      <div class="col-md-2">
        <card :title="$t('Homepage')" class="settings-card mb-3">
          <ul class="nav flex-column nav-pills">
            <li class="nav-item">
              <div class="nav-link">
                <a href="/preview" target="_blank"> Preview </a>
              </div>
            </li>
            <li class="nav-item">
              <div class="nav-link">
                <a href="/admin/page"> Setting </a>
              </div>
            </li>
          </ul>
        </card>

        <card :title="$t('Section Designer')" class="settings-card mb-3">
          <ul class="nav flex-column nav-pills">
            <li class="nav-item">
              <router-link
                :to="{ name: 'admin.cms', query: { id: -1 } }"
                class="nav-link"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  :class=" false && !showConfig && '' + form.id === '-1' ? 'active' : ''"
                  :href="href"
                  @click="navigate"
                >
                  New Section
                </a>
              </router-link>
            </li>
            <li v-for="tab in sections" :key="tab.id" class="nav-item">
              <router-link
                :to="{ name: 'admin.cms', query: { id: tab.id } }"
                class="nav-link"
                custom
                v-slot="{ href, navigate }"
              >
                <a
                  :class="false && form.id === tab.id ? 'active' : ''"
                  :href="href"
                  @click="navigate"
                >
                  {{ "" + tab.order + ": " + tab.lang + " " +  tab.dom_id }}
                </a>
              </router-link>
            </li>
          </ul>
        </card>

      </div>
      <div v-if="showConfig" class="col-lg-10 col-md-10 pl-0">
        <CmsPublish />
      </div>
      <div class="col-lg-5 col-md-5 pl-0">
        <div class="card">
          <h5
            class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <span v-if="'' + form.id === '-1'">{{ $t("New section") }}</span>
            <span v-else>{{ `${$t("Edit section")} ${form.id}` }}</span>
            <div class="btn-group" role="group">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                @click="submit"
              >
                Save
              </button>
              <button type="button" class="btn btn-sm btn-light">Cancel</button>
            </div>
          </h5>
          <form v-show="!loading" class="card-body">
            <div class="form-group row">
              <!-- <div class="col-lg-6 col-md-6">
                <label for="border_background" class="col-form-label"
                  >Border color</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="border_background"
                  placeholder="#000000"
                  v-model="form.border_background"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="border_background" class="col-form-label"
                  >Text positioning</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  placeholder="mt-10"
                  v-model="form.section_textpos"
                />
              </div> -->
              <div class="col-lg-6 col-md-6">
                <label for="section-dom_id" class="col-form-label">Tag</label>
                <input
                  type="text"
                  class="form-control"
                  id="section-dom_id"
                  placeholder="section-tag"
                  v-model="form.dom_id"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="section-dom_class" class="col-form-label"
                  >Class</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="section-dom_class"
                  placeholder="mt-10"
                  v-model="form.dom_class"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="section-next_section" class="col-form-label"
                  >Next Section</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="section-next_section"
                  placeholder="#top"
                  v-model="form.next_section"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="section-wrap_height" class="col-form-label"
                  >Order</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="section-order"
                  :placeholder="nextOrder"
                  v-model="form.order"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="section-html_only" class="col-form-label"
                  >Mode</label
                >
                <select
                  class="form-control"
                  id="section-html_only"
                  v-model="form.html_only"
                >
                  <option value="pure">Pure HTML</option>
                  <option value="basic">Basic</option>
                  <option value="basic.html">Basic w/ HTML</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6">
                <label for="section-lang" class="col-form-label"
                  >Language</label
                >
                <select
                  class="form-control"
                  id="section-lang"
                  v-model="form.lang"
                >
                  <option value="en">en</option>
                  <option value="zh-HK">zh-HK</option>
                  <option value="zh-CN">zh-CN</option>
                  <!-- <option value="pure">Pure HTML</option> -->
                </select>
              </div>
              <div class="col-lg-6 col-md-6" v-show="form.html_only.indexOf('basic')>-1">
                <label for="section-position" class="col-form-label"
                  >Position</label
                >
                <select
                  class="form-control"
                  id="section-position"
                  v-model="form.position"
                >
                  <option value="fixed">Fixed</option>
                  <option value="append">Append</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6" v-if="false">
                <label for="section-wrap_height" class="col-form-label"
                  >Wrap height</label
                >
                <select
                  class="form-control"
                  id="section-wrap_height"
                  v-model="form.wrap_height"
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div class="col-lg-12 col-md-12">
                <label for="border_background" class="col-form-label"
                  >Background</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="section-order"
                  placeholder="http://xxxx/image.png"
                  v-model="form.background_image"
                />
                <!-- <div class="p-1" style="overflow:hidden">
                  <div
                    class="image-bg"
                    :style="`background:url(${previewImage})`"
                  >
                    {{ previewImage ? "" : "No image" }}
                  </div>
                  <input type="file" accept="image/jpeg" @change="handleFile" />
                </div>
                <div style="clear: both"></div> -->
              </div>
              <div class="col-lg-12 col-md-12" v-show="form.html_only.indexOf('basic')>-1">
                <label for="section-header" class="col-form-label"
                  >Header</label
                >
                <textarea
                  style="width: 100%"
                  rows="2"
                  v-model="form.header"
                ></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="section-content"
                class="col-lg-12 col-md-12 col-form-label"
                >Content</label
              >
              <div class="col-lg-12 col-md-12" v-show="form.html_only === 'pure' || form.html_only.indexOf('html')>-1">
                <textarea
                  style="width: 100%"
                  rows="15"
                  v-model="form.html_core"
                ></textarea>
              </div>
              <div class="col-lg-12 col-md-12" v-show="form.html_only == 'basic'">
                <editor
                  ref="editor"
                  :html="form.html"
                  @onUpdate="handleUpdate"
                />
              </div>
              <div class="col-lg-12 col-md-12" v-show="form.html_only.indexOf('basic')>-1">
                <textarea
                  style="width: 100%"
                  rows="4"
                  v-model="form.html_extra"
                ></textarea>
              </div>
            </div>
            <!-- <div class="form-group row">
                    <label
                      for="section-update"
                      class="col-lg-2 col-md-12 col-form-label"
                    ></label>
                    <div class="col-lg-10 col-md-12">
                      <div
                        class="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button type="button" class="btn btn-primary">
                          Save
                        </button>
                        <button type="button" class="btn btn-light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div> -->
            <div class="form-group row">
              <div class="col-lg-6 col-md-6">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-primary" @click="submit">
                    Save
                  </button>
                  <button type="button" class="btn btn-light">Cancel</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-show="!showConfig" class="col-lg-5 col-md-6 pr-0">
        <section :key="form.id" :id="form.dom_id" :class="form.dom_class" >
          <div class="wh-100" v-if="form.html_only == 'pure'" v-html="form.html_core"></div>
          <page-section-basic :test="true" v-else :config="form" />
        </section>
      </div>
    </div>
  </section>
</template>

<style>
</style>

<script>
import axios from "axios";
import Form from "vform";
import Editor from "../../components/Editor.vue";
import CmsPublish from "../../components/application/CmsPublish.vue";
import PageSectionBasic from "../../components/PageSectionBasic.vue";

const objectToFormData = window.objectToFormData;

export default {
  middleware: "auth",
  layout: "admin",
  components: { Editor, CmsPublish, PageSectionBasic },
  //middleware: "auth",

  metaInfo() {
    return { title: this.$t("home") };
  },

  data: () => ({
    sections: [],

    showConfig: false,
    previewImage: null,

    form: new Form({
      id: -1,
      section_textpos: "pt-5 p-4",
      border_background: "#FFF",

      dom_id: "",
      dom_class: "",
      next_section: "",
      html_only: "basic",

      order: 100,
      position: "append",
      header: "",
      html: "",
      html_core: "",
      html_extra: "",
      lang: "en",

      background_image: "",
    }),

    // temp
    loading: false,
    nextOrder: 100,
  }),

  computed: {
    // ...mapGetters({
    //   token: 'auth/token'
    // }),
    contents() {
      return [];
    },
  },

  async mounted() {
    console.log("mounted");
    // return
    await this.loadSections();
    console.log("query.id", this.$route.query.id);
    if (this.$route.query.id) {
      this.showConfig = false;
      await this.loadData(this.$route.query.id);
    }
  },

  watch: {
    "$route.query.id"(to, from) {
      if (from !== to) {
        this.showConfig = false;
        console.log(to)
        this.loadData(to);
        return;
        // let result = confirm("Alert editor changes");
        // if (result) {
        //   this.form.reset();
        //   this.form.id = to;
        //   this.$forceUpdate();
        // }
      }
    },
  },

  methods: {
    async submit() {
      const { data } = await this.form.put(window.config.BASE_PATH + "/api/record/section");
      console.log(data);
      if (data.success) {
        // await this.loadData(this.$route.query.id);
        this.$router.go();
      }
    },
    loadSections: async function () {
      const { data } = await axios.get(`${window.config.BASE_PATH}/api/record/sections`);

      this.sections = data.records;
    },
    loadData: function (id) {
      this.loading = true;
      this.form.id = id || "-1";

      let editor = this.$refs.editor.editor;

      if ("" + this.form.id !== "-1") {
        axios.get(`${window.config.BASE_PATH}/api/record/section?id=${this.form.id}`)
          .then((res) => {
            console.log("section", res.data);
            if (res.data.success) {
              let record = res.data.records[0];
              Object.keys(record).forEach((r) => {
                this.form[r] = record[r];
              });
              this.form.html_core = ''+this.form.html;

              this.previewImage = this.form.background_image;

              console.log("set editor content", editor);
              editor.setContent(this.form.html, true /* emitUpdate */);
              this.loading = false;
            }
          });
      } else {
        this.form.reset();
        this.form.id = "-1";
        editor.setContent(this.form.html, true /* emitUpdate */);
        this.loading = false;
      }
    },

    handleUpdate: function (html) {
      console.log(`HTML updated ${html}`);
      var removeRegexP = /<p><\/p>/g;
      this.form.html = html.replace(removeRegexP, "<br>");
    },
    handleFile(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };

      var formData = new FormData();
      formData.append("file", file);

      axios.post(window.config.BASE_PATH + "/api/file",  formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        // Transform form data to FormData
        // transformRequest: [
        //   function (data, headers) {
        //     console.log(data);
        //     return objectToFormData(data);
        //   },
        // ],
      })
      .then((res) => {
        // this.form.background_image
        console.log(res.data);
        if (res.data.success) {
          this.form.background_image = res.data.url;
        }
      });
      // this.form
      //   .submit("post", "/upload", {
      //     // Transform form data to FormData
      //     transformRequest: [
      //       function (data, headers) {
      //         return objectToFormData(data);
      //       },
      //     ],

      //     onUploadProgress: (e) => {
      //       // Do whatever you want with the progress event
      //       // console.log(e)
      //     },
      //   })
      //   .then(({ data }) => {
      //     // ...
      //   });
    },
    pushDesign: function () {
      //  save to db and store config
    },
    generateContentFile: function () {},
  },
};
</script>

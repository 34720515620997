import { Extension } from 'tiptap';
import { extendCodemirror } from './utils/code_view';
import Logger from './utils/logger';

export const DEFAULT_CODEMIRROR_OPTIONS = {
  lineNumbers: true,
  lineWrapping: true,
  tabSize: 2,
  tabMode: 'indent',
  mode: 'text/html',
};

export default class CodeView extends Extension {
  constructor(options = {}) {
    super(options);

    // @ts-ignore
    const { codemirror } = options;
    if (codemirror == null) {
      Logger.warn('`CodeView` extension requires the CodeMirror library.');
    } else {
      extendCodemirror(codemirror);
    }

    this.options = {
      codemirror,
      codemirrorOptions: {
        ...this.defaultOptions.codemirrorOptions,
        // @ts-ignore
        ...options.codemirrorOptions,
      },
    };


    this.cmInstance = null;
  }

  get name() {
    return 'code_view';
  }

  get defaultOptions() {
    return {
      codemirror: null,
      codemirrorOptions: {
        ...DEFAULT_CODEMIRROR_OPTIONS,
      },
    };
  }

  initCodemirror(attr){
    const { codemirror, codemirrorOptions } = this.options;
    const cmOptions = {
      ...codemirrorOptions,
      readOnly: attr.hasOwnProperty('readOnly') && attr.readOnly,
      spellcheck: attr.hasOwnProperty('spellcheckEnabled') && attr.spellcheckEnabled,
    };
    this.cmInstance = codemirror.fromTextArea(
      attr.ref,
      cmOptions
    );
    this.cmInstance.setValue(attr.editor.getHTML()); // init content
    this.formatCode();
  }

  destroyCodemirror () {
    const element = this.cmInstance.doc.cm.getWrapperElement();
    element && element.remove && element.remove();
    this.cmInstance = null;
  }

  formatCode () {
    const cm = this.cmInstance;
    cm.execCommand('selectAll');
    const selectedRange = {
      from: cm.getCursor(true),
      to: cm.getCursor(false),
    };
    cm.autoFormatRange(selectedRange.from, selectedRange.to);
    cm.setCursor(0);
  }

  commands() {
    return {
      code_view: (attr)=>{
        console.log('code view', attr)

        if(attr && attr.open){
          if (!this.cmInstance) {
            // init the first time
            attr.component.$nextTick(() => this.initCodemirror(attr));
          }
        } else {
          if (this.cmInstance) {
            const content = this.cmInstance.getValue();
            attr.editor.setContent(content, true /* emitUpdate */);
            this.destroyCodemirror();
          }
        }

        return () => { }
      }
    }
  }
}